import React from 'react';
import { graphql } from 'gatsby';

import { Layout, Page as P, BlogCard, Seo } from '@app/components';

type BlogPropTypes = {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            path: string;
            cover: any;
            date: string;
          };
          html: string;
        };
      }[];
    };
  };
};

const Blog: React.FC<BlogPropTypes> = ({ data }) => {
  const { allMarkdownRemark } = data;

  return (
    <Layout>
      <Seo title="Blog - Clinic SBB International" />
      <P.Head
        title="Blog"
        date="February 5, 2022"
        breadcrumb={[
          { path: '/', name: 'Home' },
          {
            path: '/blog',
            name: 'Blog',
          },
        ]}
      />

      <div className="post-content">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 items-start">
            <div className="lg:col-span-4 py-16">
              <div className="grid lg:grid-cols-3 gap-8 items-start">
                {allMarkdownRemark.edges.map(({ node }) => (
                  <BlogCard
                    title={node.frontmatter.title}
                    path={node.frontmatter.path}
                    image={node.frontmatter.cover}
                    date={node.frontmatter.date}
                    key={node.frontmatter.path}
                    html={node.html}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query ($language: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { language: { eq: $language }, type: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            cover {
              publicURL
            }
            title
            path
            date(formatString: "MMMM D, YYYY", locale: $language)
          }
          html
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
